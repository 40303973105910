import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { State } from 'src/app/app.reducer';
import { User } from 'src/app/features/auth/models/user.type';
import { selectUser } from 'src/app/features/auth/store/auth.selector';
import { AccountType } from '../../enums/account-type.enum';

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(private readonly store: Store<State>) {}

  getCurrentAccountType(): Observable<AccountType | undefined> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? undefined : user.umatchProfile?.accountType;
      }),
      take(1)
    );
  }

  asyncGetCurrentAccountType(): Promise<AccountType | undefined> {
    return this.getCurrentAccountType().toPromise();
  }
}
