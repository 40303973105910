// vendor
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'authorization',
  },
  {
    path: '',
    children: [
      {
        path: 'onboarding',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'messages',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/messages/messages.module').then((m) => m.MessagesModule),
      },
      {
        path: 'fanbase-camp',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/fanbase-camp/fanbase-camp.module').then((m) => m.FanbaseCampModule),
      },
      {
        path: 'matching',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/matching/matching.module').then((m) => m.MatchingModule),
      },
      {
        path: 'authorization',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
