<ng-container *ngIf="screenWidth$ | async as screenWidth">
    <div cdkDrag [cdkDragData]="item" [cdkDragDisabled]="!editMode">
        <div class="song-item" fxLayout="row" fxLayoutAlign="space-between center">

            <div class="item-rating-name" fxLayout="row" fxLayoutGap="8px">

                <ng-container *ngIf="!index">

                    <div class="rating">
                        <img src="/assets/img/{{ item.rating }}.svg" alt="">
                    </div>

                </ng-container>

                <ng-container *ngIf="index">

                    <div class="name list">
                        {{ item.albumOrder }}
                    </div>

                </ng-container>

                <div fxLayout="column">
                    <div class="name">
                        {{ item?.name }}
                    </div>

                </div>

            </div>

            <ng-container *ngTemplateOutlet="editMode ? edit : view">
            </ng-container>

            <ng-template #view>
                <div class="plays">
                    {{ item?.playCount | number:'1.0':'en-US' }}
                </div>
            </ng-template>

            <ng-template #edit>
                <div fxLayout="row" fxLayoutGap="10px">
                    <img class="drugDropIcon" src="/assets/img/drug-move.svg" alt="" cdkDragHandle>
                    <img class="editIcon" src="/assets/img/pen.svg" alt="" (click)="onEdit()">
                </div>
            </ng-template>

        </div>

        <!-- with FEATS -->
        <ng-container *ngIf="editMode && item?.feats?.length">
            <div class="feats" fxLayout="row wrap">
                {{ 'DASHBOARD.ALBUM.with' | translate }}&nbsp;
                <ng-container *ngFor="let feat of item.feats; let i = index;">
                    <ng-container *ngIf="i < 3 && screenWidth > SCREEN_WIDTH || i < 1 && screenWidth <= SCREEN_WIDTH">
                        <span class="feat-link">{{ feat.name }}</span>
                        <ng-container *ngIf="((i !== 2 && screenWidth > SCREEN_WIDTH) || (i !== 0 && screenWidth <= SCREEN_WIDTH)) && i !== item.feats.length - 1">
                            <span>,&nbsp;</span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="i === 3 && screenWidth > SCREEN_WIDTH || i === 1 && screenWidth <= SCREEN_WIDTH">
                        <span class="feat-link">&nbsp;{{ 'DASHBOARD.ALBUM.and' | translate }}&nbsp;{{ item.feats.length - i }}&nbsp;{{ 'DASHBOARD.ALBUM.otherArtists' | translate }}</span>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <mat-divider></mat-divider>
    </div>
</ng-container>
