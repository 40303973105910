import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        translate.addLangs(['fr']);
        translate.setDefaultLang('en');

        const browserLang = translate.getBrowserLang();
        const defaultLanguage = translate.getDefaultLang();

        translate.use(browserLang.match(/en|fr/) ? browserLang : defaultLanguage).subscribe(
          () => {},
          (err) => {
            console.error(err);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}
