import { Action, createReducer, on } from '@ngrx/store';
import {
  registerAsPartner,
  setBand,
  setCategory,
  setLabelVariable,
  setOrganizationForm,
  setProfile,
  registerAsIndependent,
  registrationSuccess,
  registerAsCompanyRepresentative,
  registrationError,
} from './onboarding.actions';
import { Onboarding } from '../../../shared/enums/onboarding.enum';
import { OnboardingProfile } from '../models/onboarding-profile.type';
import { logoutSuccess } from '../../auth/store/auth.actions';

export const onboardingFeatureKey = 'onboarding';

export interface OnboardingState {
  musicNoteList: string[];
  isOnboarded: boolean;
  category?: Onboarding.Category;
  organizationForm?: Onboarding.OrganizationForm;
  labelVariableName?: string;
  band?: Onboarding.Band;
  profile?: OnboardingProfile;
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const initialState: OnboardingState = {
  musicNoteList: [
    'ONBOARDING.musicNote1',
    'ONBOARDING.musicNote2',
    'ONBOARDING.musicNote3',
    'ONBOARDING.musicNote4',
    'ONBOARDING.musicNote5',
  ],
  isOnboarded: false,
  category: undefined,
  organizationForm: undefined,
  labelVariableName: undefined,
  band: undefined,
  profile: undefined,
  loading: false,
  loaded: false,
  error: undefined,
};

const onboardingReducer = createReducer(
  initialState,
  on(
    setCategory,
    (state: OnboardingState, { category }): OnboardingState => {
      return {
        ...state,
        category,
      };
    }
  ),
  on(
    setOrganizationForm,
    (state: OnboardingState, { organizationForm }): OnboardingState => {
      return {
        ...state,
        organizationForm,
      };
    }
  ),
  on(setLabelVariable, (state: OnboardingState, { value }) => {
    return {
      ...state,
      labelVariableName: value,
    };
  }),
  on(
    setBand,
    (state: OnboardingState, { band }): OnboardingState => {
      return {
        ...state,
        band,
      };
    }
  ),
  on(
    setProfile,
    (state: OnboardingState, { profile }): OnboardingState => {
      return {
        ...state,
        profile,
      };
    }
  ),
  // REGISTRATION

  // As partner
  on(
    registerAsPartner,
    (state: OnboardingState): OnboardingState => {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  ),
  // As Independent
  on(
    registerAsIndependent,
    (state: OnboardingState): OnboardingState => {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  ),
  // As company representative
  on(
    registerAsCompanyRepresentative,
    (state: OnboardingState): OnboardingState => {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
  ),
  // Registration success flow
  on(
    registrationSuccess,
    (state: OnboardingState, { profile }): OnboardingState => {
      return {
        ...state,
        loading: false,
        isOnboarded: profile.profile.isOnBoarded,
        error: undefined,
      };
    }
  ),
  // Registration faild flow
  on(registrationError, (state: OnboardingState, { message }) => {
    return {
      ...state,
      loading: false,
      error: message,
    };
  }),
  // LOGOUT
  on(
    logoutSuccess,
    (state: OnboardingState): OnboardingState => {
      return {
        ...state,
        ...initialState,
      };
    }
  )
);

export function reducerOnboarding(state: OnboardingState | undefined, action: Action) {
  return onboardingReducer(state, action);
}
