import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../../app.reducer';
import { selectIsAuthenticated } from '../../features/auth/store/auth.selector';
import { go } from '../store/router.action';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private readonly store: Store<State>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated).pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.store.dispatch(go({ path: ['authorization', 'signin'] }));
          return !isAuthenticated;
        }

        return isAuthenticated;
      })
    );
  }
}
