import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requiredField',
})
export class FieldPipe implements PipeTransform {
  transform(value: string, arg: string): string | undefined {
    if (value) {
      switch (arg) {
        case 'password':
          return 'PIPES.passwordIsRequired';
        case 'email':
          return 'PIPES.emailIsRequired';
        case 'year':
          return 'PIPES.yearIsRequired';
        case 'isrcCode':
          return 'PIPES.isrcCodeIsRequired';
        case 'emailType':
          return 'PIPES.provideCorrectEmail';
        case 'title':
          return 'PIPES.titleIsRequired';
        case 'firstName':
          return 'PIPES.firstNameIsRequired';
        case 'lastName':
          return 'PIPES.lastNameIsRequired';
        case 'url':
          return 'PIPES.provideCorrectUrl';
        case 'artist':
          return 'PIPES.artistIsRequired';
        case 'musicStyle':
          return 'PIPES.musicStyleIsRequired';
        default:
          return 'PIPES.fieldIsRequired';
      }
    }
    return value;
  }
}
