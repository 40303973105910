import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../models/user.type';
import {
  authFacebook,
  authGoogle,
  authSocialSuccess,
  getUserMeSuccess,
  logoutSuccess,
  refreshTokenSuccess,
  requestForgetPasswordToken,
  requestForgetPasswordTokenError,
  requestForgetPasswordTokenFailed,
  requestForgetPasswordTokenSuccess,
  resendCode,
  signin,
  signinError, signinReset,
  signinSuccess,
  signup,
  signupError,
  signupSuccess,
  updateForgottenPassword,
  updateForgottenPasswordError,
  updateForgottenPasswordFailed,
  updateForgottenPasswordSuccess,
  validateEmail,
  validateEmailError,
  validateEmailFailed,
  validateEmailSuccess
} from "./auth.actions";

export const authFeatureKey = 'auth';

export interface AuthState extends EntityState<User> {
  user?: User;
  isAuthenticated: boolean;
  profileIsLoaded: boolean;
  accessToken?: string;
  expirationTime?: number;
  loaded: boolean;
  loading: boolean;
  emailValidationPassed: boolean;
  signupError?: string;
  signinError?: string;
  codeError?: string;
  forgetPasswordRequestError?: string;
  forgetPasswordUpdateError?: string;
  signinSocial: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

export const initialState: AuthState = adapter.getInitialState({
  user: undefined,
  isAuthenticated: false,
  profileIsLoaded: false,
  signupError: undefined,
  signinError: undefined,
  codeError: undefined,
  forgetPasswordRequestError: undefined,
  forgetPasswordUpdateError: undefined,
  accessToken: undefined,
  expirationTime: undefined,
  loaded: false,
  loading: false,
  emailValidationPassed: false,
  signinSocial: false,
});

const authReducer = createReducer(
  initialState,
  // Signup
  on(
    signup,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(signupSuccess, (state: AuthState, { user }) => {
    return adapter.upsertOne(user, {
      ...state,
      loaded: true,
      signupError: undefined,
    });
  }),
  on(signupError, (state: AuthState, { message }) => {
    return {
      ...state,
      signupError: message.includes('duplicate') ? 'This email is already used' : message,
      loaded: false,
      loading: false,
    };
  }),
  // Signin
  on(
    signin,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: true,
        signinSocial: false,
      };
    }
  ),
  on(
    signinSuccess,
    (state: AuthState, { expirationTime, accessToken }): AuthState => {
      return {
        ...state,
        accessToken,
        expirationTime,
        loading: false,
        isAuthenticated: true,
        signinError: undefined,
      };
    }
  ),
  on(signinError, (state: AuthState, { message }) => {
    return {
      ...state,
      loading: false,
      signinError: message,
    };
  }),
  on(signinReset, (state) => {
    return {
      ...state,
      loading: false,
      signinSocial: false,
      signinError: undefined,
    };
  }),
  // Social
  on(
    authFacebook,
    (state: AuthState): AuthState => {
      return {
        ...state,
        signinSocial: true,
        loading: true,
      };
    }
  ),
  on(
    authGoogle,
    (state: AuthState): AuthState => {
      return {
        ...state,
        signinSocial: true,
        loading: true,
      };
    }
  ),
  on(
    authSocialSuccess,
    (state: AuthState, { expirationTime, accessToken }): AuthState => {
      return {
        ...state,
        accessToken,
        expirationTime,
        loading: false,
        isAuthenticated: true,
      };
    }
  ),
  // Refresh token
  on(
    refreshTokenSuccess,
    (state: AuthState, { expirationTime, accessToken }): AuthState => {
      return {
        ...state,
        accessToken,
        expirationTime,
        isAuthenticated: true,
      };
    }
  ),
  // Get user's info
  on(getUserMeSuccess, (state: AuthState, { user }) => {
    return adapter.upsertOne(user, {
      ...state,
      profileIsLoaded: true,
    });
  }),
  // Email validation
  on(
    validateEmail,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    validateEmailSuccess,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: false,
        emailValidationPassed: true,
        codeError: undefined,
      };
    }
  ),
  on(
    validateEmailFailed,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: false,
        codeError: 'The code you privided is wrong',
      };
    }
  ),
  on(validateEmailError, (state: AuthState, { message }) => {
    return {
      ...state,
      loading: false,
      codeError: message,
    };
  }),
  // resend code
  on(
    resendCode,
    (state: AuthState): AuthState => {
      return {
        ...state,
        codeError: undefined,
      };
    }
  ),
  // forgotten password request token
  on(
    requestForgetPasswordToken,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: true,
        forgetPasswordRequestError: undefined,
      };
    }
  ),
  on(
    requestForgetPasswordTokenSuccess,
    (state: AuthState): AuthState => {
      return {
        ...state,
        forgetPasswordRequestError: undefined,
        loading: false,
      };
    }
  ),
  on(
    requestForgetPasswordTokenFailed,
    (state: AuthState): AuthState => {
      return {
        ...state,
        forgetPasswordRequestError: 'Email has not been sent',
        loading: false,
      };
    }
  ),
  on(requestForgetPasswordTokenError, (state: AuthState, { message }) => {
    return {
      ...state,
      forgetPasswordError: message,
      loading: false,
    };
  }),
  // Update forgotten password
  on(
    updateForgottenPassword,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: true,
        forgetPasswordUpdateError: undefined,
      };
    }
  ),
  on(
    updateForgottenPasswordSuccess,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: false,
        forgetPasswordUpdateError: undefined,
      };
    }
  ),
  on(
    updateForgottenPasswordFailed,
    (state: AuthState): AuthState => {
      return {
        ...state,
        loading: false,
        forgetPasswordUpdateError: 'Password has not been updated',
      };
    }
  ),
  on(updateForgottenPasswordError, (state: AuthState, { message }) => {
    return {
      ...state,
      loading: false,
      forgetPasswordUpdateError: message,
    };
  }),
  // LOGOUT
  on(
    logoutSuccess,
    (state: AuthState): AuthState => {
      return {
        ...state,
        ...initialState,
      };
    }
  )
);

export function reducerAuth(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
