// Angular Modules
import { Injectable } from '@angular/core';
// Application Classes
import { UrlBuilder } from '../../shared/classes/url-builder';
import { QueryStringParameters } from '../../shared/classes/query-string-parameters';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
// Returns the api endpoints urls to use in services in a consistent way
export class ApiEndpointsService {
  // Signin Social
  public signinSocial = (controller: string, path: string[]): string =>
    this.createUrlWithPathVariables(controller, [...path]);

  // Signin with Email
  public signinWithEmail = (controller: string, path: string[]): string =>
    this.createUrlWithPathVariables(controller, [...path]);

  // Refresh token
  public refreshToken = (controller: string, path: string[]): string =>
    this.createUrlWithPathVariables(controller, [...path]);

  // Logout
  public logout = (controller: string, path: string[]): string =>
    this.createUrlWithPathVariables(controller, [...path]);

  // PRIVATE METHODS
  // URL
  private createUrl(action: string): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(environment.baseUrl, action);
    return urlBuilder.toString();
  }

  // URL WITH QUERY PARAMS
  private createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(environment.baseUrl, action);
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  // URL WITH PATH VARIABLES
  private createUrlWithPathVariables(action: string, pathVariables: any[] = []): string {
    let encodedPathVariablesUrl = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(environment.baseUrl, `${action}${encodedPathVariablesUrl}`);
    return urlBuilder.toString();
  }
}
