import { Component, Inject, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DashboardPage } from 'src/app/shared/enums/dashboard-direction.enum';
import { State } from '../../../app.reducer';
import { SCREEN_WIDTH, SCREEN_WIDTH_TOKEN } from '../../../core/tokens/screen-width';
import { User } from '../../../features/auth/models/user.type';
import { logout } from '../../../features/auth/store/auth.actions';
import { selectUser } from '../../../features/auth/store/auth.selector';
import { DashboardDirection } from '../../../features/dashboard/models/dashboard-direction.type';
import { backDashboardDirection, goDashboardDirection } from '../../../features/dashboard/store/dashboard.actions';
import { selectDashboardDirection } from '../../../features/dashboard/store/dashboard.selectors';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHeaderComponent implements OnInit {
  @Output() openNav = new EventEmitter();

  readonly SCREEN_WIDTH = SCREEN_WIDTH;

  dashboardPageEnum = DashboardPage;

  constructor(
    @Inject(SCREEN_WIDTH_TOKEN) private readonly screenWidth$$: Observable<number>,
    private readonly store: Store<State>
  ) {}

  ngOnInit(): void {}

  openSideNav(): void {
    this.openNav.emit();
  }

  logout(): void {
    this.store.dispatch(logout());
  }

  onBackClick(): void {
    this.store.dispatch(backDashboardDirection());
  }

  goToProfile(): void {
    this.store.dispatch(goDashboardDirection({ direction: { page: DashboardPage.PROFILE }, path: ['/profile'] }));
  }

  // GETTERS

  public get direction$(): Observable<DashboardDirection | undefined> {
    return this.store.select(selectDashboardDirection);
  }

  public get screenWidth$(): Observable<number> {
    return this.screenWidth$$!;
  }

  public get firstLetterOfFirstName$(): Observable<string> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? 'U' : user.umatchProfile.firstName?.charAt(0).toUpperCase();
      })
    );
  }

  public get fullName$(): Observable<string> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? '' : `${user.umatchProfile.firstName} ${user.umatchProfile.lastName}`;
      })
    );
  }

  public get avatar$(): Observable<string | undefined> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? undefined : user.umatchProfile.avatarUrl;
      })
    );
  }
}
