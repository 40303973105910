<ng-container *ngIf="screenWidth$ | async as screenWidth">


    <ng-container *ngTemplateOutlet="screenWidth < SCREEN_WIDTH ? mobile : desktopAndTablets">
    </ng-container>

    <ng-template #mobile>

        <mat-sidenav-container class="main-container">
            <mat-sidenav #snav class="sidenav" mode="over">

                <div fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between">

                    <!-- TOGGLE -->

                    <ng-container *ngIf="screenWidth< SCREEN_WIDTH">

                        <div class="close-btn" fxLayout="row" fxLayoutAlign="flex-start" (click)="snav.toggle()">
                            <img src="/assets/img/close.svg" />
                        </div>

                    </ng-container>

                    <!-- LOGO -->
                    <ng-container *ngIf="screenWidth > SCREEN_WIDTH">

                        <div fxLayout="row" fxLayoutAlign="center">
                            <img src="/assets/img/logo-green.svg" />
                        </div>

                    </ng-container>

                    <!-- MENU -->
                    <mat-nav-list fxFlexOffset="60px">

                        <a mat-list-item [routerLink]="item.url" (click)="onMenuItemClick(item);snav.toggle()" [routerLinkActive]="'active-link'" *ngFor="let item of menuItems" #menu="routerLinkActive">
                            <ng-container>

                                <div class="item" fxLayout="row" fxLayoutGap="5px">
                                    <div class="img" *ngIf="menu.isActive">
                                        <img src="/assets/img/umatch_logo_sm.svg" />
                                    </div>
                                    <div [ngClass]="{'active-link': menu.isActive}">
                                        {{item.name | translate}}
                                    </div>
                                </div>

                            </ng-container>
                        </a>

                    </mat-nav-list>

                    <!-- IMAGE -->
                    <ng-container>

                        <div class="img" fxLayoutAlign="center flex-end" fxLayout="row">

                            <img src="/assets/img/music-instruments.svg" />

                        </div>

                    </ng-container>

                </div>

            </mat-sidenav>
            <mat-sidenav-content>

                <div class="main-content">
                    <app-main-header (openNav)="snav.toggle()" [displayBackButton]="displayBackButton"></app-main-header>

                    <router-outlet></router-outlet>

                </div>

            </mat-sidenav-content>
        </mat-sidenav-container>

    </ng-template>

    <ng-template #desktopAndTablets>

        <mat-drawer-container class="main-container">
            <mat-drawer class="side" mode="side" opened>

                <div fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between">

                    <!-- LOGO -->
                    <ng-container *ngIf="screenWidth > SCREEN_WIDTH">

                        <div fxLayout="row" fxLayoutAlign="end">
                            <img class="routing-element" [routerLink]="['/']" src="/assets/img/logo-green.svg" />
                        </div>

                    </ng-container>

                    <!-- MENU -->
                    <mat-nav-list fxFlexOffset="60px">

                        <a mat-list-item [routerLink]="item.url" routerLinkActive="router-link-active" (click)="onMenuItemClick(item)" [routerLinkActive]="'active-link'" *ngFor="let item of menuItems" #menu="routerLinkActive">
                            <ng-container>

                                <div class="item" fxLayout="row" fxLayoutGap="5px">
                                    <div class="img" *ngIf="menu.isActive">
                                        <img src="/assets/img/umatch_logo_sm.svg" />
                                    </div>
                                    <div [ngClass]="{'active-link': menu.isActive}">
                                        {{item.name | translate}}
                                    </div>
                                </div>

                            </ng-container>
                        </a>

                    </mat-nav-list>

                    <!-- IMAGE -->
                    <ng-container>

                        <div class="img" fxLayoutAlign="center flex-end" fxLayout="row">

                            <img src="/assets/img/music-instruments.svg" />

                        </div>

                    </ng-container>

                </div>

            </mat-drawer>
            <mat-drawer-content>

                <div class="main-content">
                    <app-main-header [displayBackButton]="displayBackButton" [currentRoute]="currentRoute"></app-main-header>

                    <router-outlet></router-outlet>

                </div>

            </mat-drawer-content>
        </mat-drawer-container>

    </ng-template>

</ng-container>
