<div class="header-container">
    <mat-toolbar class="header" #header fxLayout="row" fxLayoutAlign="space-between">

        <ng-container *ngIf="(screenWidth$ | async) < SCREEN_WIDTH && !headerText">


            <ng-container>

                <div class="user-menu" fxLayoutAlign="center center" (click)="openSideNav()">
                    <img src="/assets/img/menu-toggle-open.svg" />
                </div>

            </ng-container>

            <ng-container>
                <div fxLayoutAlign="center center">
                    <img src="/assets/img/umatch-logo.svg" />
                </div>
            </ng-container>



        </ng-container>


        <!-- BACK BUTTON && HEADER -->
        <div class="back-header-element is-flex is-align-items-center" fxLayoutGap="15px" style="width: 100%; margin-right: 20px;">
            <app-back-button *ngIf="displayBackButton" (back)="onBackClick()"
                [backArrow]="true">{{ 'SHARED.back' | translate | uppercase }}</app-back-button>

            <div class="header-element" *ngIf="headerText" style="display: inline-table;">
                    <div class="music-container">
                        <span class="icon {{headerIcon}} hidden-text" *ngIf="headerIcon"></span>
                        <div class="text hidden-text" *ngIf="headerText">
                            {{ headerText | translate | uppercase }}
                        </div>
                    </div>

            </div>

            <div *ngIf="displayInput" class="header-input" style="width: 100%">
                <app-custom-form-control-input [placeholder]="inputPlaceholder | translate" [ngModel]="inputValue" (modelChange)="onNgModelChange($event)" (submitEvent)="onSubmit()" [small]="true" [suffixIcon]="'search'"  style="width: 100%">
                </app-custom-form-control-input>
            </div>

        </div>


        <ng-container>

            <div class="msg-prof" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="15px">

                <!-- <div class="user-menu" fxLayout="row" fxLayoutGap="5px" (click)="onMessageClick()">

                    <div fxLayoutAlign="flex-end center">
                        <img src="/assets/img/message.svg" />
                    </div>

                    <div *ngIf="(screenWidth$ | async) > SCREEN_WIDTH" class="name">{{ 'DASHBOARD.HEADER.messages' |
                        translate }}</div>

                </div> -->


                <div class="user-menu" fxLayout="row" fxLayoutGap="8px" [matMenuTriggerFor]="menu" yPosition="below">

                    <div class="avatar" fxLayoutAlign="center center">

                        <ng-container *ngIf="firstLetterOfFirstName$ | async as letter">

                            <ng-container *ngIf="avatar$ | async as avatarUrl">

                                <img src="{{ avatarUrl }}" />

                            </ng-container>

                            <div class="letter" *ngIf="letter">
                                {{ letter }}
                            </div>

                        </ng-container>

                    </div>

                    <!-- TODO: What should be displayed if we don't have umatch profile -->

                    <div *ngIf="(screenWidth$ | async) > SCREEN_WIDTH" class="name">{{ fullName$ | async }}</div>

                </div>


                <!-- USER MENU -->


                <mat-menu #menu="matMenu" class="profile-menu" >
                    <button mat-menu-item (click)="goToProfile()" [disabled]="isInviteOnly">{{ 'DASHBOARD.HEADER.profile' | translate }}</button>
                    <button mat-menu-item (click)="logout()">{{ 'DASHBOARD.HEADER.logout' | translate }}</button>
                </mat-menu>

            </div>

        </ng-container>


    </mat-toolbar>

    <mat-divider></mat-divider>

</div>
