import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Component, Injector, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnectorComponent } from '../../classes/control-value-accessor-connector';

@Component({
  selector: 'app-custom-form-control-input',
  templateUrl: './custom-form-control-input.component.html',
  styleUrls: ['./custom-form-control-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomFormControlInputComponent),
    },
  ],
})
export class CustomFormControlInputComponent
  extends ControlValueAccessorConnectorComponent
  implements OnInit, OnDestroy {
  fieldFocused$?: Observable<FocusOrigin>;

  locationOptions = {
    types: ['(cities)'],
    fields: ['formatted_address', 'geometry'],
  };

  @Input() prefixIcon?: string;

  @Input() small = false;

  @Input() suffixIcon?: string;

  @Input() location?: boolean = false;

  @Output() submitEvent = new EventEmitter();

  @Output() addressChanged = new EventEmitter();

  constructor(injector: Injector, private readonly fm: FocusMonitor) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.field) {
      this.fieldFocused$ = this.fm.monitor(this.field!, true);
    }
  }

  onKeyEnter() {
    this.submitEvent.emit();
  }

  handleAddressChange(evt: any) {
    this.addressChanged.emit(evt);
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.field!);
  }

  public get isRadio() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this?.type === 'radio';
  }

  public get isCheckbox() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this?.type === 'checkbox';
  }

  public get isRadioOrCheckbox() {
    return this.isRadio || this.isCheckbox;
  }

  public get error() {
    return this.errorRequiredMsg || this.errorEmailMsg || this.errorUrlMsg || this.userAlreadyExist;
  }
}
