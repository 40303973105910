<owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
        <div class="img-wrap" fxFill fxLayoutAlign="center">
            <div class="text" fxFill fxFlex="100%" fxLayoutAlign="center center">
                We’re musicians. We make music for a living. It’s that simple. Nothing else matters.
            </div>
            <div fxFlex="100%">
                <img class="main-img" src="../../../../assets/img/carousel-1.png">
            </div>
            <div class="vector" fxFlex="77%" fxFill>
                <img src="../../../../assets/img/logo-form-1.png">
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="img-wrap" fxLayoutAlign="center">
            <div class="text" fxFill fxFlex="100%" fxLayoutAlign="center center">Only music can bring us together.</div>
            <div fxFlex="100%">
                <img class="main-img" src="../../../../assets/img/carousel-2.png">
            </div>
            <div class="vector" fxFlex="77%" fxFill>
                <img src="../../../../assets/img/logo-form-2.png">
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="img-wrap" fxLayoutAlign="center">
            <div class="text" fxFill fxFlex="100%" fxLayoutAlign="center center">We believe that music is everybody’s
                business.</div>
            <div fxFlex="100%">
                <img class="main-img" src="../../../../assets/img/carousel-3.png">
            </div>
            <div class="vector" fxFlex="77%" fxFill>
                <img src="../../../../assets/img/logo-form-3.png">
            </div>
        </div>
    </ng-template>
</owl-carousel-o>