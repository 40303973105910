import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DashboardState,
  adapterAlbum,
  adapterTopSong,
  adapterManagers,
  adapterArtists,
  adapterPlaylists,
  adapterSong,
} from './dashboard.reducer';

export const selectFeature = createFeatureSelector<DashboardState>('dashboard');

export const selectAlbumState = (state: DashboardState) => state.albums;

export const selectSongState = (state: DashboardState) => state.songs;

export const selectTopSongState = (state: DashboardState) => state.topSongs;

export const selectManagersState = (state: DashboardState) => state.managers;

export const selectArtistsState = (state: DashboardState) => state.artists;

export const selectPlaylistsState = (state: DashboardState) => state.playlists;

export const selectDashboardDirection = createSelector(
  selectFeature,
  ({ direction }) => direction[direction.length - 1]
);

export const { selectAll: selectAllAlbums } = adapterAlbum.getSelectors();

export const { selectAll: selectAllSongs } = adapterSong.getSelectors();

export const { selectAll: selectAllTopSongs } = adapterTopSong.getSelectors();

export const { selectAll: selectAllManagers } = adapterManagers.getSelectors();

export const { selectAll: selectAllArtists } = adapterArtists.getSelectors();

export const { selectAll: selectAllPlaylists } = adapterPlaylists.getSelectors();

export const selectAlbums = createSelector(selectFeature, (state) => Object.values(state.albums.entities));

export const selectSongs = createSelector(selectFeature, (state) => Object.values(state.songs.entities));

export const selectTopSongs = createSelector(selectFeature, (state) => Object.values(state.topSongs.entities));

export const selectManagers = createSelector(selectFeature, (state) => Object.values(state.managers.entities));

export const selectArtists = createSelector(selectFeature, (state) => Object.values(state.artists.entities));

export const selectPlaylists = createSelector(selectFeature, (state) => Object.values(state.playlists.entities));
