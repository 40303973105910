import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { InterceptorSkipHeader } from 'src/app/core/interceptors/token.interceptor';
import { HttpHeaders } from '@angular/common/http';
import { selectExpirationTime } from '../store/auth.selector';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import {
  validateEmailMutation,
  resendCode,
  requestForgetPasswordToken,
  forgetPassword,
  createUserMutation,
} from '../graphql/mutations';
import { ApiService } from '../../../core/services/api.service';
import { ApiEndpointsService } from '../../../core/services/api-endpoints.service';

import { User } from '../models/user.type';
import { getUserMe } from '../graphql/queries';

import { State } from '../../../app.reducer';
import { refreshToken } from '../store/auth.actions';

@Injectable()
export class AuthService {
  constructor(
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly apiService: ApiService,
    private readonly store: Store<State>
  ) {}

  signinFacebook(): Observable<any> {
    return this.apiService.get(this.apiEndpointsService.signinSocial('authentication', ['facebook']));
  }

  signinGoogle(): Observable<any> {
    return this.apiService.get(this.apiEndpointsService.signinSocial('authentication', ['google']));
  }

  signupWithEmail(
    email: string,
    password: string,
    invitationToken: string | undefined = undefined,
    ambassadorToken: string | undefined = undefined
  ): Observable<User> {
    return this.apiService.graphql(createUserMutation(email, password, invitationToken, ambassadorToken)).pipe(
      map((res) => {
        if (res.data) {
          return res.data.createUser;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  signinWithEmail(email: string, password: string): Observable<{ expirationTime: number; accessToken: string }> {
    return this.apiService
      .post(this.apiEndpointsService.signinWithEmail('authentication', ['login']), { email, password })
      .pipe(shareReplay());
  }

  refresh(): Observable<{ expirationTime: number; accessToken: string }> {
    return this.apiService
      .post(this.apiEndpointsService.refreshToken('authentication', ['refresh']), {})
      .pipe(shareReplay());
  }

  startRefreshCountDown(): void {
    this.store
      .select(selectExpirationTime)
      .pipe(
        tap((expirationTime?: number) => {
          const refreshTime = expirationTime! - +DateTime.local() - 120000;
          setTimeout(() => {
            this.store.dispatch(refreshToken());
          }, refreshTime);
        }),
        take(1)
      )
      .subscribe();
  }

  validateEmail(emailCode: string): Observable<{ status: ResponseStatus }> {
    return this.apiService.graphql(validateEmailMutation(emailCode)).pipe(
      map((res) => {
        if (res.data) {
          return res.data.validateEmail;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  resendCode(): Observable<{ status: ResponseStatus }> {
    return this.apiService.graphql(resendCode()).pipe(
      map((res) => {
        if (res.data) {
          return res.data.sendBackEmail;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  requestPasswordToken(email: string): Observable<{ status: ResponseStatus }> {
    return this.apiService.graphql(requestForgetPasswordToken(email)).pipe(
      map((res) => {
        if (res.data) {
          return res.data.requestForgetPasswordToken;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  updatePassword(token: string, newPassword: string): Observable<{ status: ResponseStatus }> {
    return this.apiService.graphql(forgetPassword(token, newPassword)).pipe(
      map((res) => {
        if (res.data) {
          return res.data.forgetPassword;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  getUmatchProfile(): Observable<any> {
    return this.apiService.graphql(getUserMe()).pipe(
      map((res) => {
        if (res.data) {
          return res.data.getUserMe;
        }
        if (res.errors) {
          throw new Error(res.errors[0].message);
        }
      })
    );
  }

  logout(): Observable<void> {
    return this.apiService.delete(this.apiEndpointsService.logout('authentication', ['logout']), {
      headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
    });
  }
}
