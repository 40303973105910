<div fxFlex="100%" fxLayout="column">
    <div class="cover-container" fxLayoutGap="20px" fxLayout="row">
        <div fxFlex="60%">
          <div *ngIf="album?.coverUrl" class="cover-container" >
            <img class="cover" src="{{ album?.coverUrl }}" alt="">
          </div>
          <div *ngIf="!album?.coverUrl" class="cover-container no-img">
            <img class="cover" src="{{'../../../../assets/img/no-image.png' }}" alt="">
          </div>
        </div>


        <div fxLayout="column" fxLayoutGap="8px" fxFlex="40%">
            <div> {{ album?.releaseDate | date: 'yyyy' }} </div>
            <div class="title"> {{ album?.title }} </div>
            <div> {{ songs?.length }} {{ 'DASHBOARD.ALBUM.songs' | translate }} </div>
            <div (click)="onEdit()" class="edit-circle" *ngIf="!disableEdit">
                <img src="/assets/img/edit-circle.svg" alt="">
            </div>
        </div>
    </div>
    <div class="list-container">
        <app-song-item *ngFor="let song of songs; let i = index; trackBy: trackById" [index]="i + 1" [item]="song"></app-song-item>
    </div>
</div>
