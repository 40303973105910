<div class="button-primary" fxLayout="row" fxLayoutAlign="center center" [matMenuTriggerFor]="menu" yPosition="below"
    (click)="$event.stopPropagation()">
    {{ (currentLang$ | async | uppercase) || langInitialValue | uppercase }}
    <div class="img">
        <img src="../../../../assets/img/chevron-down.svg" alt="">
    </div>
</div>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changeLang('fr')">FR</button>
    <button mat-menu-item (click)="changeLang('en')">EN</button>
</mat-menu>