import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomDestroyComponent } from '../../classes/custom-destroy';

@Component({
  selector: 'app-lang-button',
  templateUrl: './lang-button.component.html',
  styleUrls: ['./lang-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangButtonComponent extends CustomDestroyComponent implements OnInit {
  constructor(private readonly translate: TranslateService) {
    super();
  }

  ngOnInit(): void {}

  changeLang(lang: string): void {
    this.translate.use(lang);
  }

  // GETTERS

  get currentLang$(): Observable<string> {
    return this.translate.onLangChange.pipe(
      takeUntil(this.destroy$),
      map((currentLang) => currentLang.lang)
    );
  }

  get langInitialValue(): string {
    return this.translate.currentLang;
  }
}
