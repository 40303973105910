import { Component, OnInit } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import { selectIsAuthenticated } from './features/auth/store/auth.selector';
import { refreshToken, getUserMe } from './features/auth/store/auth.actions';
import { State } from './app.reducer';
import { CustomDestroyComponent } from './shared/classes/custom-destroy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CustomDestroyComponent implements OnInit {
  GOOGLE_PLACES_KEY = environment.GOOGLE_PLACES_KEY;

  constructor(public readonly translate: TranslateService, private readonly store: Store<State>) {
    super();
  }

  ngOnInit(): void {
    this.loadPlacesScript();
    this.watchTokeRefreshed();
    this.store.select(selectIsAuthenticated).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch(refreshToken());
      }
    });
  }

  private loadPlacesScript() {
    const node = document.createElement('script');
    node.src = `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_PLACES_KEY}&libraries=places&language=en`;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  // Private methods

  private watchTokeRefreshed(): void {
    this.store
      .select(selectIsAuthenticated)
      .pipe(
        filter((e) => !!e),
        first()
      )
      .subscribe(() => this.store.dispatch(getUserMe()));
  }
}
