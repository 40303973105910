import * as gql from 'gql-query-builder';

// Get user info (me)
export const getUserMe = (): unknown => {
  const fields = [
    'id',
    'musicStyle',
    'coverUrl',
    'avatarUrl',
    { socialLink: ['facebook', 'youtube', 'instagram', 'twitter', 'tiktok'] },
    { geolocation: ['locationName', { location: ['lat', 'lon'] }] },
  ];

  return gql.query({
    operation: 'getUserMe',
    fields: [
      'id',
      'email',
      'lang',
      'login',
      'roles',
      'isActive',
      'isEmailValidated',
      'isSocialLogin',
      {
        umatchProfile: [
          'isOnBoarded',
          'accountType',
          'firstName',
          'lastName',
          {
            accountTypeDetail: [
              {
                '... on Partner': [...fields, 'pseudonym', 'description'],
              },
              {
                '... on MusicMaker': [
                  {
                    memberOf: [
                      ...fields,
                      'biography',
                      'name',
                      'writeAccess',
                      'isGroup',
                      {
                        memberList: [
                          'artistId',
                          'musicRole',
                          'id',
                          'isLoggedInUser',
                          'isManager',
                          'firstName',
                          'lastName',
                          'invitationDate',
                          'approvalDate',
                          'invitationEmail',
                          'musicMakerId',
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                '... on Manager': [
                  {
                    label: [...fields, 'description', 'name', 'isAdmin'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
};
