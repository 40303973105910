import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith, shareReplay } from 'rxjs/operators';
import { WINDOW } from './window';

export const SCREEN_WIDTH = 1025;

export const SCREEN_WIDTH_TOKEN = new InjectionToken<Observable<number>>('Screen width stream', {
  factory: () => {
    const { defaultView } = inject(DOCUMENT);
    const { innerWidth } = inject(WINDOW);

    return fromEvent!(defaultView!, 'resize').pipe(
      startWith!(innerWidth!),
      map(() => defaultView!.innerWidth),
      shareReplay({ refCount: true })
    );
  },
});
