import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() isValid?: boolean;

  @Input() img = '';

  @Input() loading = false;

  @Input() class = 'button-primary';

  @Output()
  submitClick = new EventEmitter();

  isClicked = false;

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.isValid) {
      this.isClicked = true;
      this.submitClick.emit();
    }
  }
}
