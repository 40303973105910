import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Component, Injector, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { ControlValueAccessorConnectorComponent } from '../../classes/control-value-accessor-connector';

@Component({
  selector: 'app-custom-form-control-select',
  templateUrl: './custom-form-control-select.component.html',
  styleUrls: ['./custom-form-control-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomFormControlSelectComponent),
    },
  ],
})
export class CustomFormControlSelectComponent
  extends ControlValueAccessorConnectorComponent
  implements OnInit, OnDestroy {
  fieldFocused$?: Observable<FocusOrigin>;

  constructor(injector: Injector, private readonly fm: FocusMonitor) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.field) {
      this.fieldFocused$ = this.fm.monitor(this.field!, true);
    }
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.field!);
  }
}
