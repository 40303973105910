import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable, Inject } from '@angular/core';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { AuthService } from '../services/auth.service';
import {
  signup,
  signupSuccess,
  signupError,
  validateEmailSuccess,
  validateEmail,
  validateEmailFailed,
  resendCode,
  validateEmailError,
  signin,
  signinSuccess,
  signinError,
  requestForgetPasswordToken,
  requestForgetPasswordTokenSuccess,
  requestForgetPasswordTokenFailed,
  requestForgetPasswordTokenError,
  updateForgottenPassword,
  updateForgottenPasswordFailed,
  updateForgottenPasswordSuccess,
  updateForgottenPasswordError,
  authGoogle,
  authFacebook,
  getUserMe,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenError,
  getUserMeSuccess,
  logout,
  logoutSuccess,
  authSocialSuccess,
} from './auth.actions';
import { State } from '../../../app.reducer';
import { User } from '../models/user.type';
import { go } from '../../../core/store/router.action';
import { LOCATION } from '../../../core/tokens/location';

@Injectable()
export class AuthEffects {
  // Signup
  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signup),
      switchMap(({ credentials }) => {
        return this.authService
          .signupWithEmail(
            credentials.email,
            credentials.password,
            credentials?.invitationToken,
            credentials?.ambassadorToken
          )
          .pipe(
            map((user) => {
              this.store.dispatch(signin({ credentials }));
              return signupSuccess({ user });
            }),
            catchError(({ message }) => of(signupError({ message })))
          );
      })
    )
  );

  // Signin
  signin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signin),
      switchMap(({ credentials }) => {
        return this.authService.signinWithEmail(credentials.email, credentials.password).pipe(
          map(({ expirationTime, accessToken }) => {
            return signinSuccess({ expirationTime, accessToken });
          }),
          catchError(() => of(signinError({ message: this.translate.instant('AUTH.wrong') })))
        );
      })
    )
  );

  /*
   Get user profile
  */
  getUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signinSuccess || authSocialSuccess),
        tap(() => this.store.dispatch(getUserMe()))
      ),
    { dispatch: false }
  );

  getUserMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserMe),
      switchMap(() => {
        return this.authService.getUmatchProfile().pipe(
          map((user: User) => {
            return getUserMeSuccess({ user });
          })
        );
      })
    )
  );

  // Social
  google$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authGoogle),
        tap(({ location }) => this.location.replace(location))
      ),
    { dispatch: false }
  );

  facebook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authFacebook),
        tap(({ location }) => this.location.replace(location))
      ),
    { dispatch: false }
  );

  // Refresh
  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      switchMap(() => {
        return this.authService.refresh().pipe(
          map(({ expirationTime, accessToken }) => {
            return refreshTokenSuccess({ expirationTime, accessToken });
          }),
          catchError(() => of(refreshTokenError({ message: 'Failed' })))
        );
      })
    )
  );

  // Scheduled refresh token
  refreshCountDown$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenSuccess || signinSuccess || authSocialSuccess),
        tap(() => this.authService.startRefreshCountDown())
      ),
    { dispatch: false }
  );

  // Validate email
  validateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateEmail),
      switchMap(({ emailCode }) => {
        return this.authService.validateEmail(emailCode).pipe(
          map((res: { status: ResponseStatus }) => {
            return res.status === ResponseStatus.OK ? validateEmailSuccess() : validateEmailFailed();
          }),
          catchError(({ message }) => of(validateEmailError({ message })))
        );
      })
    )
  );

  // Resend code
  resendCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resendCode),
        tap(() => {
          this.authService.resendCode().pipe(take(1)).subscribe();
        })
      ),
    { dispatch: false }
  );

  // Request forgotten password token
  requestForgetPasswordToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestForgetPasswordToken),
      switchMap(({ email }) => {
        return this.authService.requestPasswordToken(email).pipe(
          map((res: { status: ResponseStatus }) => {
            return res.status === ResponseStatus.OK
              ? requestForgetPasswordTokenSuccess()
              : requestForgetPasswordTokenFailed();
          }),
          catchError(({ message }) => of(requestForgetPasswordTokenError({ message })))
        );
      })
    )
  );

  // Update forgotten password
  updateForgottenPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateForgottenPassword),
      switchMap(({ token, newPassword }) => {
        return this.authService.updatePassword(token, newPassword).pipe(
          map((res: { status: ResponseStatus }) => {
            return res.status === ResponseStatus.OK
              ? updateForgottenPasswordSuccess()
              : updateForgottenPasswordFailed();
          }),
          catchError(({ message }) => of(updateForgottenPasswordError({ message })))
        );
      })
    )
  );

  updateForgottenPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateForgottenPasswordSuccess),
        tap(() => {
          this.store.dispatch(go({ path: ['authorization', 'signin'] }));
        })
      ),
    { dispatch: false }
  );

  // Logout
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        switchMap(() => {
          return this.authService.logout().pipe(
            catchError(() => of([])),
            tap(() => {
              this.store.dispatch(logoutSuccess());
              this.store.dispatch(go({ path: ['authorization', 'signin'] }));
            })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly store: Store<State>,
    private readonly translate: TranslateService,
    @Inject(LOCATION) private readonly location: Location
  ) {}
}
