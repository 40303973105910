import { createAction, props } from '@ngrx/store';
import { CompanyRepresentativeRegistrationInput, Manager } from '../models/manager.type';
import { IndependentRegistrationInput, MusicMaker } from '../models/music-maker.type';
import { OnboardingProfile } from '../models/onboarding-profile.type';
import { Onboarding } from '../../../shared/enums/onboarding.enum';
import { Partner, PartnerRegistrationInput } from '../models/partner.type';

export const setCategory = createAction('[Onboarding] Initial step', props<{ category: Onboarding.Category }>());
export const setOrganizationForm = createAction(
  '[Onboarding] Step 1',
  props<{ organizationForm: Onboarding.OrganizationForm }>()
);
export const setLabelVariable = createAction('[Onboarding] Define label variable', props<{ value: string }>());
export const setBand = createAction('[Onboarding] Step 2', props<{ band: Onboarding.Band }>());
export const setProfile = createAction('[Onboarding] Step 3', props<{ profile: OnboardingProfile }>());

// Registration
export const registerAsPartner = createAction(
  '[Onboarding] Registration as partner',
  props<{ credentials: PartnerRegistrationInput }>()
);
export const registerAsIndependent = createAction(
  '[Onboarding] Registration as independent artist',
  props<{ credentials: IndependentRegistrationInput }>()
);
export const registerAsCompanyRepresentative = createAction(
  '[Onboarding] Registration as label',
  props<{ credentials: CompanyRepresentativeRegistrationInput }>()
);

export const registrationSuccess = createAction(
  '[Onboarding] Registration success',
  props<{ profile: Partner | MusicMaker | Manager }>()
);
export const registrationError = createAction('[Onboarding] Registration error', props<{ message: string }>());
