export enum DashboardPage {
  // *** DASHBOARD ***
  DASHBOARD = 'dashboard',
  LIBRARY_MUSIC = 'musicLibrary',
  ALBUM = 'album',
  SONG = 'song',
  PLAYLIST = 'playlist',

  // *** FAN_BASE_CAMP ***
  FAN_BASE_CAMP = 'fanBaseCamp',

  // *** MATCHING ***
  MATCHING = 'matching',

  // *** CROWDFUNDING ***
  CROWDFUNDING = 'Crowdfunding',

  // *** PROFILE ***
  PROFILE = 'profie',
}
