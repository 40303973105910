import { RouterReducerState } from '@ngrx/router-store';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'src/app/app.reducer';
import { go } from 'src/app/core/store/router.action';
import { SCREEN_WIDTH, SCREEN_WIDTH_TOKEN } from 'src/app/core/tokens/screen-width';
import { MenuItem } from 'src/app/features/dashboard/models/menu-item.type';
import { DashboardPage } from 'src/app/shared/enums/dashboard-direction.enum';
import { DashboardMenu } from '../../enums/dashboard-menu.enum';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  readonly SCREEN_WIDTH = SCREEN_WIDTH;

  menuItems: MenuItem[] = [
    { name: DashboardMenu.DASHBOARD, direction: DashboardPage.DASHBOARD, url: '/dashboard/dashboard' },
    // { name: DashboardMenu.FAN_BASE_CAMP, direction: DashboardPage.FAN_BASE_CAMP, url: '/fanbase-camp' },
    // { name: DashboardMenu.MATCHING, direction: DashboardPage.MATCHING, url: '/matching' },
    // { name: DashboardMenu.CROWDFUNDING, direction: DashboardPage.CROWDFUNDING, url: '/dashboard/crowdfunding' },
  ];

  displayBackButton = false;

  currentRoute = '/';

  constructor(
    @Inject(SCREEN_WIDTH_TOKEN) private readonly screenWidth$$: Observable<number>,
    private readonly store: Store<State>
  ) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.router)
      .subscribe((state: RouterReducerState) => {
        this.currentRoute = state.state.url;
        this.calculateBackButton();
      });
    this.calculateBackButton();
  }

  calculateBackButton() {
    this.displayBackButton = !this.menuItems.find(
      (item) => [item.url, `${item.url}/home`].indexOf(this.currentRoute) !== -1
    );
  }

  onMenuItemClick(item: MenuItem): void {
    this.store.dispatch(go({ path: [item.url] }));
  }

  // GETTERS

  public get screenWidth$(): Observable<number> {
    return this.screenWidth$$;
  }
}
