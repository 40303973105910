import { Action, createReducer, on } from '@ngrx/store';
import { updateHeader, cleanHeader, catchBackHeader, sendCatchEvent, sendInputSubmittedEvent } from './header.action';

export interface HeaderState {
  headerIcon?: string | undefined;
  headerText?: string | undefined;
  avatarUrl?: string | undefined;
  catchBack?: boolean | undefined;
  displayInput?: boolean;
  inputValue?: unknown | undefined;
  inputPlaceholder?: string | undefined;
  lastCatchedEvent?: number | undefined;
  lastInputSubmittedEvent?: number | undefined;
}

export const headerFeatureKey = 'header';

export const initialState: HeaderState = {
  headerIcon: undefined,
  headerText: undefined,
  avatarUrl: undefined,
  catchBack: false,
  displayInput: false,
  inputPlaceholder: undefined,
  lastInputSubmittedEvent: new Date().getTime(),
  lastCatchedEvent: new Date().getTime(),
};

const headerReducer = createReducer(
  initialState,
  on(updateHeader, (state: HeaderState, headerData) => {
    return {
      ...state,
      ...headerData,
    };
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  on(cleanHeader, (state: HeaderState) => {
    return {
      ...state,
      headerIcon: undefined,
      headerText: undefined,
      avatarUrl: undefined,
      displayInput: false,
      inputValue: undefined,
      inputPlaceholder: undefined,
    };
  }),

  on(catchBackHeader, (state: HeaderState, data) => {
    return { ...state, ...data };
  }),

  on(sendCatchEvent, (state: HeaderState) => {
    return { ...state, lastCatchedEvent: new Date().getTime() };
  }),

  on(sendInputSubmittedEvent, (state: HeaderState) => {
    return { ...state, lastInputSubmittedEvent: new Date().getTime() };
  })
);

export function reducerHeader(state: HeaderState | undefined, action: Action) {
  return headerReducer(state, action);
}
