import { OwlOptions } from 'ngx-owl-carousel-o';

export const customOptions: OwlOptions = {
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: true,
  autoplay: true,
  autoplayTimeout: 7000,
  autoplaySpeed: 1000,
  navSpeed: 700,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    740: {
      items: 1,
    },
    940: {
      items: 1,
    },
  },
  nav: false,
  animateOut: 'fadeOut'
};
