import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/app.reducer';
import { goDashboardDirection } from 'src/app/features/dashboard/store/dashboard.actions';
import { AccountType } from '../../enums/account-type.enum';
import { MyTopList } from '../../enums/my-top-list.enum';
import { DashboardPage } from '../../enums/dashboard-direction.enum';

@Component({
  selector: 'app-my-top-empty',
  templateUrl: './my-top-empty.component.html',
  styleUrls: ['./my-top-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTopEmptyComponent implements OnInit {
  @Input() accountType?: AccountType;

  account = AccountType;

  listName = MyTopList;

  constructor(private readonly store: Store<State>) {}

  ngOnInit(): void {}

  goToLibrary() {
    this.store.dispatch(
      goDashboardDirection({
        direction: {
          page: DashboardPage.LIBRARY_MUSIC,
        },
        path: ['dashboard/dashboard/music-library'],
      })
    );
  }
}
