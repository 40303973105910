import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerFactory } from './app-init';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    // core
    CoreModule,
    SharedModule,

    // Notification System
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 5,
      autoDismiss: true,
      newestOnTop: true,
      positionClass: 'toast-top-center',
      progressBar: true,
      closeButton: true,
    }),

    // app routes
    AppRoutingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
