<div fxLayout="row" fxLayoutAlign="space-between">
    <div class="button-secondary" [ngClass]="{'disabled-back': loading }" fxLayout="row" fxLayoutAlign="center center"
        (click)="onNoCLick()">
        {{ 'SHARED.back' | translate | uppercase }}
    </div>
    <div class="button-primary" fxLayout="row" fxLayoutAlign="center center"
        [ngClass]="{'disabled': !isValid, 'spinner loading': loading}" (click)="onSubmit()">
        {{ 'SHARED.next' | translate | uppercase }}
        <mat-icon *ngIf="loading && isClicked" class="spinner">
            <mat-spinner diameter="20">
            </mat-spinner>
        </mat-icon>
    </div>
</div>