<div class="{{ class }} btn" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="9px" [ngClass]="{'disabled': !isValid || loading}"
    (click)="onSubmit()">

    <!-- IMAGE -->
    <ng-container *ngIf="img">

        <img src="../../../../assets/img/{{ img }}.svg">

    </ng-container>

    <ng-content></ng-content>

    <!-- SPINNER -->
    <mat-icon *ngIf="loading && isClicked" class="spinner">

        <mat-spinner diameter="20">
        </mat-spinner>

    </mat-icon>

</div>
