import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ConfirmModalComponent } from './confirm-modal.component';

export interface ModalOptions {
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfirmModalService {
  constructor(private dialog: MatDialog) {}

  openDialog(options: ModalOptions = {}) {
    const dialogRef = this.dialog.open(ConfirmModalComponent);

    for (const [key, value] of Object.entries(options)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dialogRef.componentInstance[key] = value;
    }

    return dialogRef.afterClosed().pipe(map((e) => e === true));
  }
}
