import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent implements OnInit {
  @Input() isAbsouteBtn = false;

  @Output()
  back = new EventEmitter();

  @Input() backArrow = false;

  ngOnInit(): void {}

  onNoCLick(): void {
    this.back.emit();
  }

  onBackClick(): void {
    this.back.emit();
  }
}
