import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { AccountType } from '../../enums/account-type.enum';
import { RoleService } from './role-guard.service';

@Component({
  selector: 'app-role-guard',
  templateUrl: './role-guard.component.html',
  styleUrls: ['./role-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleGuardComponent implements OnChanges {
  @Input() roles: (AccountType | unknown)[] = [];

  @ContentChild(TemplateRef) detailRef: any;

  display = false;

  constructor(private roleService: RoleService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roles.currentValue) {
      this.updateDisplay();
    }
  }

  updateDisplay() {
    this.roleService.getCurrentAccountType().subscribe((currentAccountType) => {
      this.display = this.roles.indexOf(currentAccountType || '') !== -1;
    });
  }
}
