import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnectorComponent } from '../../classes/control-value-accessor-connector';

@Component({
  selector: 'app-custom-form-control-textarea',
  templateUrl: './custom-form-control-textarea.component.html',
  styleUrls: ['./custom-form-control-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomFormControlTextareaComponent,
    },
  ],
})
export class CustomFormControlTextareaComponent
  extends ControlValueAccessorConnectorComponent
  implements OnInit, OnDestroy {
  fieldFocused$?: Observable<FocusOrigin>;

  constructor(injector: Injector, private readonly fm: FocusMonitor) {
    super(injector);
  }

  ngOnInit(): void {
    this.fieldFocused$ = this.fm.monitor(this.field!, true);
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.field!);
  }
}
