import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SCREEN_WIDTH, SCREEN_WIDTH_TOKEN } from 'src/app/core/tokens/screen-width';
import { Song } from 'src/app/shared/models/song.type';

@Component({
  selector: 'app-song-item',
  templateUrl: './song-item.component.html',
  styleUrls: ['./song-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SongItemComponent implements OnInit {
  @Input() item?: Song;

  @Input() index?: number;

  @Input() editMode?: boolean;

  @Output() editClick = new EventEmitter();

  readonly SCREEN_WIDTH = SCREEN_WIDTH;

  constructor(@Inject(SCREEN_WIDTH_TOKEN) private readonly screenWidth$$: Observable<number>) {}

  ngOnInit(): void {}

  onEdit() {
    this.editClick.emit();
  }

  // GETTERS

  public get screenWidth$(): Observable<number> {
    return this.screenWidth$$;
  }
}
