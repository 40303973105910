import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],

  encapsulation: ViewEncapsulation.None,
})
export class ConfirmModalComponent implements OnInit {
  @Input() title = 'SHARED.MODALS.CONFIRM.title';

  @Input() message = 'SHARED.MODALS.CONFIRM.message';

  @Input() cancelText = 'SHARED.MODALS.CONFIRM.cancel';

  @Input() confirmText = 'SHARED.MODALS.CONFIRM.confirm';

  ngOnInit(): void {}

  onConfirm(): void {}
}
