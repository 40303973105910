import { MyTopHeaderComponent } from 'src/app/shared/components/my-top-header/my-top-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { ResponsiveComponent } from './classes/responsive-component';
import { ConfirmModalService } from './components/confirm-modal/confirm-modal.service';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MainHeaderComponent } from './layout/main-header/main-header.component';
import { MainLayoutComponent } from './layout/main/main.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';

import { ButtonComponent } from './components/button/button.component';
import { MaterialModule } from './material.module';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ButtonsGroupComponent } from './components/buttons-group/buttons-group.component';
import { LangButtonComponent } from './components/lang-button/lang-button.component';
import { NgxShowDirective } from './directives/ngx-show.directive';
import { NgxHideDirective } from './directives/ngx-hide.directive';
import { CustomFormControlInputComponent } from './components/custom-form-control-input/custom-form-control-input.component';
import { CustomFormControlSelectComponent } from './components/custom-form-control-select/custom-form-control-select.component';
import { ArtistPlaceholderPipe } from './pipes/artist-placeholder.pipe';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { FieldPipe } from './pipes/field-required.pipe';
import { CustomFormControlTextareaComponent } from './components/custom-form-control-textarea/custom-form-control-textarea.component';
import { RoleGuardComponent } from './components/role-guard/role-guard.component';
import { LocalizedNumberPipe } from './pipes/localized-number.pipe';
import { IsInviteOnlyComponent } from './classes/invite-only-component';
import { MyTopEmptyComponent } from './components/my-top-empty/my-top-empty.component';
import { SongItemComponent } from './components/song-item/song-item.component';
import { AlbumCoverComponent } from './components/album-cover/album-cover.component';

const DECLARATIONS = [
  ButtonComponent,
  CarouselComponent,
  BackButtonComponent,
  ButtonsGroupComponent,
  BackButtonComponent,
  RoleGuardComponent,
  LangButtonComponent,
  NgxShowDirective,
  NgxHideDirective,
  CustomFormControlInputComponent,
  CustomFormControlSelectComponent,
  ArtistPlaceholderPipe,
  DashboardHeaderComponent,
  FieldPipe,
  LocalizedDatePipe,
  LocalizedNumberPipe,
  MainLayoutComponent,
  MainHeaderComponent,
  CustomFormControlTextareaComponent,
  ConfirmModalComponent,
  ResponsiveComponent,
  IsInviteOnlyComponent,
  MyTopHeaderComponent,
  MyTopEmptyComponent,
  SongItemComponent,
  AlbumCoverComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    // vendor
    CommonModule,
    FormsModule,
    GooglePlaceModule,
    ReactiveFormsModule,
    RouterModule,

    // flex
    FlexLayoutModule,

    // material
    MaterialModule,

    // Carousel
    CarouselModule,

    // @RxwebValidator
    RxReactiveFormsModule,

    // translate
    TranslateModule,

    // Drug-Drop Files
    NgxFileDropModule,
  ],
  exports: [
    ...DECLARATIONS,
    // flex
    FlexLayoutModule,

    // material
    MaterialModule,

    // vendor
    CommonModule,
    FormsModule,
    // NG SELECT
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,

    // carousel
    CarouselModule,

    // @RxwebValidator
    RxReactiveFormsModule,

    // Drug-Drop Files
    NgxFileDropModule,
  ],

  providers: [ConfirmModalService],
})
export class SharedModule {}
