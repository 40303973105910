import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SCREEN_WIDTH } from '../../core/tokens/screen-width';

@Directive({ selector: '[appNgxShow]' })
export class NgxShowDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set appNgxShow(condition: number) {
    if (condition < SCREEN_WIDTH) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
