<div class="top-header" fxLayout="row" fxLayoutAlign="space-between center">

    <div class="title" fxLayout="row" fxLayoutGap="5px">

        <img [src]="'/assets/img/' + icon + '.svg'" alt="">

        <div class="text">
            {{ title | translate | uppercase }}
        </div>

    </div>

    <div class="btn" *ngIf="nvgBtnExist">
        <app-button [class]="'button-red'" [isValid]="true" (submitClick)="goToLibrary()">
            {{ buttonLabel | translate | uppercase }}
        </app-button>
    </div>
</div>
