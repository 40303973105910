import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'artistPlaceholder',
})
export class ArtistPlaceholderPipe implements PipeTransform {
  transform(value: string): string | undefined {
    switch (value) {
      case 'artistLabel':
        return 'ONBOARDING.artistPlaceholder';
      case 'groupLabel':
        return 'ONBOARDING.groupPlaceholder';
      case 'pseudonymLabel':
        return 'ONBOARDING.pseudonymPlaceholder';
      case 'labelLabel':
        return 'ONBOARDING.labelPlaceholder';
      default:
        return value;
    }
  }
}
