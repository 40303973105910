import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SCREEN_WIDTH, SCREEN_WIDTH_TOKEN } from 'src/app/core/tokens/screen-width';

@Component({
  template: '',
})
export class ResponsiveComponent implements OnInit {
  screenWidth?: any;

  isMobile = false;

  isDesktop = false;

  readonly SCREEN_WIDTH = SCREEN_WIDTH;

  constructor(@Inject(SCREEN_WIDTH_TOKEN) readonly screenWidth$: Observable<number>) {
    const { innerWidth } = window;
    this.screenWidth = innerWidth;
    this.calculateVariables();
  }

  ngOnInit() {
    this.screenWidth$.subscribe((val) => {
      this.screenWidth = val;
      this.calculateVariables();
    });
  }

  calculateVariables() {
    this.isMobile = !!(this.screenWidth && this.screenWidth <= this.SCREEN_WIDTH);
    this.isDesktop = !!(this.screenWidth && this.screenWidth > this.SCREEN_WIDTH);
  }
}
