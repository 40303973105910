import { Action, createReducer, on } from '@ngrx/store';
import { profileLoaded } from './profile.actions';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProfileState = {
  loading: false,
  loaded: false,
};

const profileReducer = createReducer(
  initialState,
  on(profileLoaded, (state: ProfileState) => {
    return {
      ...state,
      loaded: true,
    };
  })
);

export function reducerProfile(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}
