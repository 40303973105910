import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Song } from 'src/app/shared/models/song.type';
import { Album } from '../../../features/dashboard/models/album.type';

@Component({
  selector: 'app-album-cover',
  templateUrl: './album-cover.component.html',
  styleUrls: ['./album-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbumCoverComponent implements OnInit {
  @Input() album?: Album;

  @Input() songs?: Song[];

  @Input() disableEdit = false;

  @Output()
  editClick = new EventEmitter();

  ngOnInit(): void {}

  onEdit(): void {
    this.editClick.emit();
  }

  trackById(index: number, item: any): string {
    return item.id;
  }
}
