<div class="header-container">
    <mat-toolbar class="header" #header fxLayout="row" fxLayoutAlign="space-between">

        <ng-container *ngIf="direction$ | async as direction">


            <ng-container *ngIf="(screenWidth$ | async) < SCREEN_WIDTH">

                <ng-container *ngIf="direction.page === dashboardPageEnum.DASHBOARD || direction.page === dashboardPageEnum.FAN_BASE_CAMP || direction.page === dashboardPageEnum.MATCHING || direction.page === dashboardPageEnum.CROWDFUNDING">

                    <ng-container>

                        <div class="user-menu" fxLayoutAlign="center center" (click)="openSideNav()">
                            <img src="/assets/img/menu-toggle-open.svg" />
                        </div>

                    </ng-container>

                    <ng-container>
                        <div fxLayoutAlign="center center">
                            <img src="/assets/img/umatch-logo.svg" />
                        </div>
                    </ng-container>


                </ng-container>

            </ng-container>


            <!-- BACK BUTTON && DIRECTION -->
            <ng-container *ngIf="direction.page === dashboardPageEnum.LIBRARY_MUSIC || direction.page === dashboardPageEnum.ALBUM || direction.page === dashboardPageEnum.SONG || direction.page === dashboardPageEnum.PLAYLIST">
                <div class="back-header-element" fxLayout="row" fxLayoutGap="15px">
                    <app-back-button fxLayoutAlign="center center" (back)="onBackClick()" [backArrow]="true">{{ 'SHARED.back' | translate | uppercase }}</app-back-button>

                    <div class="header-element" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5px">


                        <ng-container *ngIf="direction.page === dashboardPageEnum.SONG && direction.dashboardSelectedItem">
                            <div class="music-container">
                                <img src="/assets/img/music.svg" alt="">
                                <div class="text hidden-text">
                                    {{ direction.dashboardSelectedItem }}
                                </div>
                            </div>
                        </ng-container>

                    </div>

                </div>
            </ng-container>


            <ng-container>

                <div class="msg-prof" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="15px">

                    <!-- 
                    <div class="user-menu" fxLayout="row" fxLayoutGap="5px">

                        <div fxLayoutAlign="flex-end center">
                            <img src="/assets/img/message.svg" />
                        </div>

                        <div *ngIf="(screenWidth$ | async) > SCREEN_WIDTH" class="name">{{ 'DASHBOARD.HEADER.messages' | translate }}</div>

                    </div> -->

                    <ng-container *ngIf="direction.page !== dashboardPageEnum.PROFILE">

                        <div class="user-menu" fxLayout="row" fxLayoutGap="8px" [matMenuTriggerFor]="menu" yPosition="below">

                            <div class="avatar" fxLayoutAlign="center center">

                                <ng-container *ngIf="firstLetterOfFirstName$ | async as letter">

                                    <ng-container *ngIf="avatar$ | async as avatarUrl">

                                        <img src="{{ avatarUrl }}" />

                                    </ng-container>

                                    <div class="letter" *ngIf="letter">
                                        {{ letter }}
                                    </div>

                                </ng-container>

                            </div>

                            <!-- TODO: What should be displayed if we don't have umatch profile -->

                            <div *ngIf="(screenWidth$ | async) > SCREEN_WIDTH" class="name">{{ fullName$ | async }}</div>

                        </div>

                    </ng-container>

                    <!-- USER MENU -->


                    <mat-menu #menu="matMenu" class="profile-menu">
                        <button mat-menu-item (click)="goToProfile()">{{ 'DASHBOARD.HEADER.profile' | translate }}</button>
                        <button mat-menu-item (click)="logout()">{{ 'DASHBOARD.HEADER.logout' | translate }}</button>
                    </mat-menu>

                </div>

            </ng-container>

        </ng-container>

    </mat-toolbar>

    <mat-divider></mat-divider>

</div>
