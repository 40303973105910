import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-buttons-group',
  templateUrl: './buttons-group.component.html',
  styleUrls: ['./buttons-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsGroupComponent implements OnInit {
  @Input() isValid: boolean | undefined;

  @Input() loading = false;

  @Output()
  submitClick = new EventEmitter();

  @Output()
  back = new EventEmitter();

  isClicked = false;

  ngOnInit(): void {}

  onSubmit(): void {
    this.isClicked = true;
    this.submitClick.emit();
  }

  onNoCLick(): void {
    this.back.emit();
  }
}
