import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DashboardPage } from 'src/app/shared/enums/dashboard-direction.enum';
import { Artist } from 'src/app/shared/models/artist.type';
import { logoutSuccess } from '../../auth/store/auth.actions';
import { Album } from '../models/album.type';
import { DashboardDirection } from '../models/dashboard-direction.type';
import { Manager } from '../models/manager.type';
import { Playlist } from '../models/playlist.type';
import { Song } from '../../../shared/models/song.type';
import {
  loadAlbumsSuccess,
  loadTopSongsSuccess,
  loadManagersSuccess,
  loadArtistsSuccess,
  loadPlaylistsSuccess,
  addAlbumSuccess,
  changeAlbumSuccess,
  changeSongSuccess,
  addSongSuccess,
  deleteAlbumSuccess,
  deleteSongSuccess,
  backDashboardDirection,
  goDashboardDirection,
  setDashboardDirection,
  loadSongsSuccess,
  addPlaylistSuccess,
  changePlaylistSuccess,
  deletePlaylistSuccess,
} from './dashboard.actions';

export const dashboardFeatureKey = 'dashboard';

export interface AlbumState extends EntityState<Album> {
  total?: number;
}

export interface SongState extends EntityState<Song> {
  total?: number;
}

export interface TopSongState extends EntityState<Song> {
  total?: number;
}

export interface ManagersState extends EntityState<Manager> {
  total?: number;
}

export interface ArtistsState extends EntityState<Artist> {
  total?: number;
}

export interface PlaylistsState extends EntityState<Playlist> {
  total?: number;
}

export interface DashboardState {
  direction: DashboardDirection[];
  loading: boolean;
  albums: AlbumState;
  songs: SongState;
  topSongs: TopSongState;
  managers: ManagersState;
  artists: ArtistsState;
  playlists: PlaylistsState;
}

// TODO: Change type
export const adapterAlbum = createEntityAdapter<Album>({
  selectId: (album) => album.id,
});

export const adapterSong = createEntityAdapter<Song>({
  selectId: (song) => song.id,
});

export const adapterTopSong = createEntityAdapter<Song>({
  selectId: (song) => song.id,
});

export const adapterManagers = createEntityAdapter<Manager>({
  selectId: (manager) => manager.id,
});

export const adapterArtists = createEntityAdapter<Artist>({
  selectId: (artist) => artist.id,
});

export const adapterPlaylists = createEntityAdapter<Playlist>({
  selectId: (playlist) => playlist.id,
});

const albumInitialState: AlbumState = adapterAlbum.getInitialState({ total: 0 });

const songInitialState: SongState = adapterSong.getInitialState({ total: 0 });

const topSongInitialState: TopSongState = adapterTopSong.getInitialState({ total: 0 });

const managersInitialState: ManagersState = adapterManagers.getInitialState({ total: 0 });

const artistsInitialState: ArtistsState = adapterArtists.getInitialState({ total: 0 });

const playlistsInitialState: PlaylistsState = adapterPlaylists.getInitialState({ total: 0 });

export const initialState: DashboardState = {
  direction: [
    {
      page: DashboardPage.DASHBOARD,
      dashboardSelectedItem: undefined,
    },
  ],
  loading: false,
  albums: albumInitialState,
  songs: songInitialState,
  topSongs: topSongInitialState,
  managers: managersInitialState,
  artists: artistsInitialState,
  playlists: playlistsInitialState,
};

const dashboardReducer = createReducer(
  initialState,
  on(
    setDashboardDirection,
    (state: DashboardState, { direction }): DashboardState => {
      return {
        ...state,
        direction: [direction],
      };
    }
  ),
  on(
    goDashboardDirection,
    (state: DashboardState, { direction }): DashboardState => {
      return {
        ...state,
        direction: [...state.direction, direction],
      };
    }
  ),
  on(
    backDashboardDirection,
    (state: DashboardState): DashboardState => {
      return {
        ...state,
        direction: state.direction.slice(0, -1),
      };
    }
  ),
  on(loadAlbumsSuccess, (state: DashboardState, { albums }) => {
    return {
      ...state,
      albums: adapterAlbum.upsertMany(albums, { ...state.albums, total: albums.length }),
    };
  }),
  on(addAlbumSuccess, (state: DashboardState, { album }) => {
    return {
      ...state,
      albums: adapterAlbum.upsertOne(album, { ...state.albums }),
    };
  }),
  on(changeAlbumSuccess, (state: DashboardState, { album }) => {
    return {
      ...state,
      albums: adapterAlbum.updateOne({ id: album.id, changes: { ...album } }, { ...state.albums }),
    };
  }),
  on(deleteAlbumSuccess, (state: DashboardState, { id }) => {
    return {
      ...state,
      albums: adapterAlbum.removeOne(id, { ...state.albums }),
    };
  }),
  on(loadSongsSuccess, (state: DashboardState, { songs }) => {
    return {
      ...state,
      songs: adapterSong.upsertMany(songs, { ...state.songs, total: songs.length }),
    };
  }),
  on(addSongSuccess, (state: DashboardState, { song }) => {
    return {
      ...state,
      songs: adapterSong.upsertOne(song, { ...state.songs }),
    };
  }),
  on(changeSongSuccess, (state: DashboardState, { song }) => {
    return {
      ...state,
      songs: adapterSong.updateOne({ id: song.id, changes: { ...song } }, { ...state.songs }),
    };
  }),
  on(deleteSongSuccess, (state: DashboardState, { id }) => {
    return {
      ...state,
      songs: adapterSong.removeOne(id, { ...state.songs }),
    };
  }),
  on(loadTopSongsSuccess, (state: DashboardState, { topSongs }) => {
    return {
      ...state,
      topSongs: adapterTopSong.upsertMany(topSongs, { ...state.topSongs, total: topSongs.length }),
    };
  }),
  on(loadManagersSuccess, (state: DashboardState, { managers }) => {
    return {
      ...state,
      managers: adapterManagers.upsertMany(managers, { ...state.managers, total: managers.length }),
    };
  }),
  on(loadArtistsSuccess, (state: DashboardState, { artists }) => {
    return {
      ...state,
      artists: adapterArtists.upsertMany(artists, { ...state.artists, total: artists.length }),
    };
  }),
  on(loadPlaylistsSuccess, (state: DashboardState, { playlists }) => {
    return {
      ...state,
      playlists: adapterPlaylists.upsertMany(playlists, { ...state.playlists, total: playlists.length }),
    };
  }),
  on(addPlaylistSuccess, (state: DashboardState, { playlist }) => {
    return {
      ...state,
      playlists: adapterPlaylists.upsertOne(playlist, { ...state.playlists }),
    };
  }),
  on(changePlaylistSuccess, (state: DashboardState, { playlist }) => {
    return {
      ...state,
      playlists: adapterPlaylists.updateOne({ id: playlist.id, changes: { ...playlist } }, { ...state.playlists }),
    };
  }),
  on(deletePlaylistSuccess, (state: DashboardState, { id }) => {
    return {
      ...state,
      playlists: adapterPlaylists.removeOne(id, { ...state.playlists }),
    };
  }),
  // LOGOUT
  on(
    logoutSuccess,
    (state: DashboardState): DashboardState => {
      return {
        ...state,
        ...initialState,
      };
    }
  )
);

export function reducerDashboard(state: DashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
