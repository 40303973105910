import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, mergeMap, tap } from 'rxjs/operators';
import { logout } from 'src/app/features/auth/store/auth.actions';
import { State } from '../../app.reducer';
import { selectAccessToken } from '../../features/auth/store/auth.selector';
import { WINDOW } from '../tokens/window';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<State>, @Inject(WINDOW) private readonly window: Window, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectAccessToken).pipe(
      first(),
      mergeMap((token) => {
        const authReq = token
          ? req.clone({
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                // TODO: should be language from language button, no?
                'Accept-Language': this.window.navigator.language,
              }),
            })
          : req;
        return next.handle(authReq);
      }),
      tap(
        () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (req.body?.errors && req.body?.errors.some((e) => e.message === 'Unauthorized')) {
            this.store.dispatch(logout());
          }
        },
        (err: any) => {
          if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
          }

          if (err instanceof HttpErrorResponse) {
            // Logout if Access Forbidden or API is not online (Security)
            if ([401, 504].indexOf(err.status) === -1) {
              return;
            }

            this.store.dispatch(logout());
          }
        }
      )
    );
  }
}
