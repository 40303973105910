import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false,
})
export class LocalizedNumberPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any): any {
    const locale = this.translateService.currentLang === 'fr' ? 'fr-FR' : 'en-GB';
    return new Intl.NumberFormat(locale).format(value);
  }
}
