import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import formatRelative from 'date-fns/formatRelative';

import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-GB';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'mediumDate'): any {
    const locale = this.translateService.currentLang === 'fr' ? 'fr-FR' : 'en-GB';

    if (pattern === 'relative') {
      return formatRelative(value, new Date(), { locale: locale === 'fr-FR' ? frLocale : enLocale });
    }
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern, undefined, locale);
  }
}
