<div class="form-section" fxLayout="column" fxLayoutGap="5px">
    <div class="label {{ labelClass }}">{{ label }}</div>
    <small *ngIf="errorRequiredMsg">{{ errorRequiredMsg }}</small>
    <select *ngIf="control || formControlName" [id]="formControlName" #field class="select {{ inputClass }}" [disabled]="disabled" [formControl]="control" [ngClass]="{'invalid': errorRequiredMsg || errorEmailMsg, 'focused': fieldFocused$ | async}">
        <option *ngFor="let option of selectValues;" [value]="option.value">{{ option.key }}</option>
    </select>

    <select *ngIf="!control && !formControlName" [id]="formControlName" #field class="select {{ inputClass }}" [disabled]="disabled" [(ngModel)]="ngModel" (ngModelChange)="onNgModelChange($event)" [ngClass]="{'invalid': errorRequiredMsg || errorEmailMsg, 'focused': fieldFocused$ | async}">
        <option *ngFor="let option of selectValues;" [value]="option.value">{{ option.key }}</option>
    </select>
</div>
