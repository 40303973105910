<div class="form-section" fxLayout="column" fxLayoutGap="5px" *ngIf="!isRadioOrCheckbox">
    <label class="label {{ labelClass }}"><i *ngIf="icon" class="icon {{icon}}"></i>{{ label }}</label>

    <div class="help-message" *ngIf="helpMessage">{{helpMessage}}</div>
    <small class="error-message" *ngIf="error">{{ error }}</small>

    <div class="input-group is-flex is-align-items-space-between is-align-items-center" [ngClass]="{'is-invalid': error, 'focused': fieldFocused$ | async, 'is-small': small}">
      <i *ngIf="prefixIcon" class="icon {{prefixIcon}}"></i>

      <input (keydown.enter)="onKeyEnter()" *ngIf="!location && (!control && !formControlName)" [id]="formControlName" #field autocomplete="off" class="form-input  {{ inputClass }}" type="{{ type }}" placeholder="{{ placeholder }}" [ngModel]="ngModel" (ngModelChange)="onNgModelChange($event)" [disabled]="disabled"/>
      <input (keydown.enter)="onKeyEnter()" *ngIf="!location && (control || formControlName)" [id]="formControlName" #field autocomplete="off" class="form-input  {{ inputClass }}"  type="{{ type }}" placeholder="{{ placeholder }}" [formControl]="control" [disabled]="disabled"/>
      <input (keydown.enter)="onKeyEnter()" *ngIf="location" [options]='locationOptions' ngx-google-places-autocomplete [id]="formControlName" #field autocomplete="off" class="form-input  {{ inputClass }}" type="{{ type }}" placeholder="{{ placeholder }}" [disabled]="disabled" (onAddressChange)="handleAddressChange($event)"/>

      <button class="suffix-icon-btn" *ngIf="suffixIcon" (click)="onKeyEnter()">
        <i class="icon {{suffixIcon}}"></i>
      </button>
    </div>


</div>

<div *ngIf="isRadio" class="radio-item">
  <input type="radio" #field [name]="formControlName" [id]="formControlName + value" [formControl]="control" [value]="value"/>
  <label [for]="formControlName + value" class="{{labelClass}}">{{label}}</label>
</div>

<div *ngIf="isCheckbox" class="checkbox-item">
  <input type="checkbox" #field [name]="formControlName" [id]="formControlName + value" [formControl]="control" [value]="value"/>
  <label [for]="formControlName + value" class="{{labelClass}}">{{label}}</label>
</div>
