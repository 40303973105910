// vendor
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, Provider, SkipSelf } from '@angular/core';

// materia;
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// guard

// translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from '@angular/cdk/layout';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { AuthEffects } from '../features/auth/store/auth.effects';

// ngrx
import { RouterCustomSerializer } from './store/router.reducer';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';

import { RouterEffect } from './store/router.effect';
import { reducers, metaReducers } from '../app.reducer';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from '../features/auth/services/auth.service';

// Translation loader
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const PROVIDERS: Provider[] = [
  {
    provide: RouterStateSerializer,
    useClass: RouterCustomSerializer,
  },
  // token interceptor
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [],
  imports: [
    // vendor
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,

    // shared
    SharedModule,

    // http
    HttpClientModule,

    // translate
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // ngrx
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([RouterEffect, AuthEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterCustomSerializer,
    }),
    StoreRouterConnectingModule,
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 10 }) : [],
  ],
  providers: [AuthService, ...PROVIDERS],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
