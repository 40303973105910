import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, AuthState } from './auth.reducer';

export const selectFeature = createFeatureSelector<AuthState>('auth');

const adapterSelectors = adapter.getSelectors();

export const selectIsAuthenticated = createSelector(selectFeature, ({ isAuthenticated }) => isAuthenticated);

export const selectProfileIsLoaded = createSelector(selectFeature, ({ profileIsLoaded }) => profileIsLoaded);

export const selectAccessToken = createSelector(selectFeature, ({ accessToken }) => accessToken);

export const selectExpirationTime = createSelector(selectFeature, ({ expirationTime }) => expirationTime);

export const selectAuthSignupError = createSelector(selectFeature, ({ signupError }) => signupError);

export const selectSigninError = createSelector(selectFeature, ({ signinError }) => signinError);

export const selectAuthCodeError = createSelector(selectFeature, ({ codeError }) => codeError);

export const selectAuthForgetPasswordError = createSelector(
  selectFeature,
  ({ forgetPasswordRequestError }) => forgetPasswordRequestError
);

export const selectLoaded = createSelector(selectFeature, ({ loaded }) => loaded);

export const selectLoading = createSelector(selectFeature, ({ loading }) => loading);

export const selectUser = createSelector(selectFeature, (state) => adapterSelectors.selectAll(state)[0]);

export const selectUserId = createSelector(selectFeature, (state) => state.ids[0]);

export const selectIsUserEmailValidated = createSelector(
  selectFeature,
  (state) => state.entities[Object.keys(state.entities)[0]]?.isEmailValidated || false
);

export const selectArtisId = createSelector(
  selectFeature,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (state) => state.entities[Object.keys(state.entities)[0]]?.umatchProfile.accountTypeDetail.memberOf.id
);

export const selectEmailValidationPassed = createSelector(
  selectFeature,
  ({ emailValidationPassed }) => emailValidationPassed
);

export const selectSigninSocial = createSelector(selectFeature, ({ signinSocial }) => signinSocial);
