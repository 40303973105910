<div class="empty-container" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center">

    <!-- MUSIC MAKER -->
    <ng-container *ngIf="accountType === account.MUSIC_MAKER">

        <app-my-top-header fxFill [listName]="listName.SONGS" [emptyList]="true" (goToLibraryEvent)="goToLibrary()"></app-my-top-header>

        <div class="title">{{ 'DASHBOARD.DASHBOARD.youAreHome' | translate }}</div>

         <div class="subtitle">
               {{ 'DASHBOARD.DASHBOARD.itIsEmpty' | translate }} {{ 'DASHBOARD.DASHBOARD.go' | translate }} <span class="red">{{ 'DASHBOARD.DASHBOARD.addYourMusic' | translate }}</span> {{ 'DASHBOARD.DASHBOARD.inLibrary' | translate }}
        </div>

         <div class="img">
           <img src="/assets/img/musician.svg" alt="">
        </div>

    </ng-container>

    <!-- LABEL -->
    <ng-container fxFill *ngIf="accountType === account.COMPANY_REPRESENTATIVE">

        <app-my-top-header [listName]="listName" [emptyList]="true" (goToLibraryEvent)="goToLibrary()"></app-my-top-header>

        <div class="title">{{ 'DASHBOARD.DASHBOARD.youAreHome' | translate }}</div>

         <div class="subtitle">
               {{ 'DASHBOARD.DASHBOARD.itIsEmpty' | translate }} {{ 'DASHBOARD.DASHBOARD.go' | translate }} <span class="red">{{ 'DASHBOARD.DASHBOARD.addYourManagers' | translate }}</span> {{ 'DASHBOARD.DASHBOARD.and' | translate }} <span class="red">{{ 'DASHBOARD.DASHBOARD.artists' | translate | lowercase }}</span>  {{ 'DASHBOARD.DASHBOARD.inLibrary' | translate }}
        </div>

         <div class="img">
           <img src="/assets/img/musician.svg" alt="">
        </div>

    </ng-container>


</div>
