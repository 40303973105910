import { updateHeader } from 'src/app/shared/layout/store/header.action';
import { Component, Inject, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { sendInputSubmittedEvent, sendCatchEvent } from '../store/header.action';
import { back, go } from '../../../core/store/router.action';
import { State } from '../../../app.reducer';
import { SCREEN_WIDTH, SCREEN_WIDTH_TOKEN } from '../../../core/tokens/screen-width';
import { User } from '../../../features/auth/models/user.type';
import { logout } from '../../../features/auth/store/auth.actions';
import { selectUser } from '../../../features/auth/store/auth.selector';

import { IsInviteOnlyComponent } from '../../classes/invite-only-component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent extends IsInviteOnlyComponent implements OnInit {
  @Output() openNav = new EventEmitter();

  private inputValue$ = new Subject();

  @Input() displayBackButton = false;

  readonly SCREEN_WIDTH = SCREEN_WIDTH;

  public headerText?: any;

  public displayInput?: boolean;

  public catchBack?: any;

  public headerIcon?: any;

  public inputValue?: any;

  public inputPlaceholder?: any;

  constructor(
    @Inject(SCREEN_WIDTH_TOKEN) private readonly screenWidth$$: Observable<number>,
    private readonly store: Store<State>,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select('header').subscribe((state) => {
      this.headerText = state?.headerText || undefined;
      this.inputPlaceholder = state?.inputPlaceholder || undefined;
      this.displayInput = state?.displayInput || undefined;
      this.headerIcon = state?.headerIcon || undefined;
      this.catchBack = state?.catchBack || undefined;
      this.cd.detectChanges();
    });

    this.inputValue$.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      if (this.displayInput) {
        this.store.dispatch(updateHeader({ inputValue: value }));
      }
    });
  }

  onSubmit() {
    this.store.dispatch(sendInputSubmittedEvent());
  }

  onNgModelChange(value: unknown) {
    this.inputValue$.next(value);
  }

  openSideNav(): void {
    this.openNav.emit();
  }

  logout(): void {
    this.store.dispatch(logout());
  }

  onBackClick(): void {
    if (this.catchBack) {
      this.store.dispatch(sendCatchEvent());
      return;
    }
    this.store.dispatch(back());
  }

  goToProfile(): void {
    this.store.dispatch(go({ path: ['/profile'] }));
  }

  onMessageClick(): void {
    this.store.dispatch(go({ path: ['/messages'] }));
  }

  // GETTERS
  public get screenWidth$(): Observable<number> {
    return this.screenWidth$$!;
  }

  public get firstLetterOfFirstName$(): Observable<string> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? 'U' : user.umatchProfile.firstName?.charAt(0).toUpperCase();
      })
    );
  }

  public get fullName$(): Observable<string> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        const u = user.umatchProfile.firstName
          ? user.umatchProfile
          : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user.umatchProfile!.accountTypeDetail.memberOf.memberList?.find((m) => m?.isLoggedInUser);

        if (!u) {
          return '';
        }

        if (u.firstName) {
          return `${u.firstName} ${u.lastName}`;
        }

        return u.email;
      })
    );
  }

  public get avatar$(): Observable<string | undefined> {
    return this.store.select(selectUser).pipe(
      filter((e) => !!e),
      map((user: User) => {
        return !user.umatchProfile ? undefined : user.umatchProfile.avatarUrl;
      })
    );
  }
}
