<div class="confim-modal">
  <h2 mat-dialog-title>{{title | translate}}</h2>

  <mat-dialog-content class="mat-typography">
    <p [innerHtml]="message | translate"></p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="confirm-modal__btn--cancel" mat-button mat-dialog-close>{{cancelText | translate}}</button>
    <button class="confirm-modal__btn--confirm" mat-button [mat-dialog-close]="true">{{confirmText | translate}}</button>
  </mat-dialog-actions>

</div>
