import { createAction, props } from '@ngrx/store';
import { RequestSignin } from '../models/request-signin.type';
import { RequestSignup } from '../models/request-signup.type';
import { User } from '../models/user.type';

// Signup actions
export const signup = createAction('[Auth] Signup', props<{ credentials: RequestSignup }>());
export const signupSuccess = createAction('[Auth] Signup success', props<{ user: User }>());
export const signupError = createAction('[Auth] Signup error', props<{ message: string }>());

// Signin actions
export const signin = createAction('[Auth] Signin', props<{ credentials: RequestSignin }>());
export const signinReset = createAction('[Auth] Signin reset');
export const signinSuccess = createAction(
  '[Auth] Signin success',
  props<{ expirationTime: number; accessToken: string }>()
);
export const signinError = createAction('[Auth] Signin error', props<{ message: string }>());

// Social
export const authFacebook = createAction('[Auth] Facebook', props<{ location: string }>());
export const authFacebookError = createAction('[Auth] Facebook error', props<{ message: string }>());

export const authGoogle = createAction('[Auth] Google', props<{ location: string }>());
export const authGoogleError = createAction('[Auth] Google error', props<{ message: string }>());

export const authSocialSuccess = createAction(
  '[Auth] Auth social success',
  props<{ expirationTime: number; accessToken: string }>()
);

// Refresh token
export const refreshToken = createAction('[Auth] Refresh token');
export const refreshTokenSuccess = createAction(
  '[Auth] Refresh token success',
  props<{ expirationTime: number; accessToken: string }>()
);
export const refreshTokenError = createAction('[Auth] Refresh token error', props<{ message: string }>());

export const refreshCountDown = createAction('[Auth] Scheduled resresh token');

// Fetch user's info
export const getUserMe = createAction("[Auth] Get user's info");
export const getUserMeSuccess = createAction("[Auth] Get user's info success", props<{ user: User }>());

// Validate email actions
export const validateEmail = createAction('[Auth] Validate email', props<{ emailCode: string }>());
export const validateEmailSuccess = createAction('[Auth] Validate email success');
export const validateEmailFailed = createAction('[Auth] Validate email failed');
export const validateEmailError = createAction('[Auth] Validate email error', props<{ message: string }>());

// Resend code
export const resendCode = createAction('[Auth] Resend code');

// Forgor password
export const requestForgetPasswordToken = createAction(
  '[Auth] Request forgotten password token',
  props<{ email: string }>()
);
export const requestForgetPasswordTokenSuccess = createAction('[Auth] Request forgotten password token success');
export const requestForgetPasswordTokenFailed = createAction('[Auth] Request forgotten password token failed');
export const requestForgetPasswordTokenError = createAction(
  '[Auth] Request forgotten password token error',
  props<{ message: string }>()
);

// Update password
export const updateForgottenPassword = createAction(
  '[Auth] Update forgotten password',
  props<{ token: string; newPassword: string }>()
);
export const updateForgottenPasswordSuccess = createAction('[Auth] Update forgotten password success');
export const updateForgottenPasswordFailed = createAction('[Auth] Update forgotten password failed');
export const updateForgottenPasswordError = createAction(
  '[Auth] Update forgotten password error',
  props<{ message: string }>()
);

// Logout
export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout success');
