import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';
import * as authStore from './features/auth/store/auth.reducer';
import * as onboardingStore from './features/onboarding/store/onboarding.reducer';
import * as dashboardStore from './features/dashboard/store/dashboard.reducer';
import * as profileStore from './features/profile/store/profile.reducer';
import * as headerStore from './shared/layout/store/header.reducer';

export interface State {
  router: RouterReducerState;
  auth: authStore.AuthState;
  onboarding: onboardingStore.OnboardingState;
  dashboard: dashboardStore.DashboardState;
  profile: profileStore.ProfileState;
  header: headerStore.HeaderState;
}
export const reducers: ActionReducerMap<State, Action> = {
  router: routerReducer,
  auth: authStore.reducerAuth,
  onboarding: onboardingStore.reducerOnboarding,
  dashboard: dashboardStore.reducerDashboard,
  profile: profileStore.reducerProfile,
  header: headerStore.reducerHeader,
};

export const metaReducers: MetaReducer<State>[] = [appMetaReducer, localStorageSyncReducer];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    rehydrate: true,
    keys: ['auth'],
  })(reducer);
}

export function appMetaReducer(reducer: any) {
  return function newReducer(state: any, action: any) {
    const nextState = state;
    return reducer(nextState, action);
  };
}
