import { ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MyTopList } from '../../enums/my-top-list.enum';

@Component({
  selector: 'app-my-top-header',
  templateUrl: './my-top-header.component.html',
  styleUrls: ['./my-top-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTopHeaderComponent implements OnInit {
  @Input() listName!: MyTopList;

  @Output() goToLibraryEvent = new EventEmitter();

  @Input() emptyList = false;

  @Input() nvgBtnExist = true;

  myTopList = MyTopList;

  ngOnInit(): void {}

  goToLibrary(): void {
    this.goToLibraryEvent.emit();
  }

  get title() {
    switch (this.listName) {
      case this.myTopList.SONGS:
        return 'DASHBOARD.DASHBOARD.myTopMusic';
      case this.myTopList.MANAGERS:
        return 'DASHBOARD.DASHBOARD.managers';
      case this.myTopList.ARTISTS:
        return 'DASHBOARD.DASHBOARD.artists';
      case this.myTopList.PLAYLISTS:
        return 'DASHBOARD.DASHBOARD.myTopPlaylists';

      default:
        return this.listName;
    }
  }

  get icon() {
    switch (this.listName) {
      case this.myTopList.SONGS:
        return 'top-note';
      case this.myTopList.MANAGERS:
        return 'manager';
      case this.myTopList.ARTISTS:
        return 'manager';
      case this.myTopList.PLAYLISTS:
        return 'playlist-menu-60';

      default:
        return 'top-note';
    }
  }

  get buttonLabel() {
    switch (this.listName) {
      case this.myTopList.SONGS:
        return !this.emptyList ? 'DASHBOARD.DASHBOARD.goToLibrary' : 'DASHBOARD.DASHBOARD.addMusic';
      case this.myTopList.MANAGERS:
        return !this.emptyList ? 'DASHBOARD.DASHBOARD.viewAll' : 'DASHBOARD.DASHBOARD.addManager';
      case this.myTopList.ARTISTS:
        return 'DASHBOARD.DASHBOARD.artists';
      case this.myTopList.PLAYLISTS:
        return !this.emptyList ? 'DASHBOARD.DASHBOARD.viewAll' : 'DASHBOARD.DASHBOARD.addArtist';

      default:
        return 'DASHBOARD.DASHBOARD.viewAll';
    }
  }
}
