import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  template: '',
})
export class IsInviteOnlyComponent {
  public get isInviteOnly() {
    return environment.inviteOnly;
  }

  public get isNotInviteOnly() {
    return !this.isInviteOnly;
  }
}
