import * as gql from 'gql-query-builder';

// signup
export const createUserMutation = (
  email: string,
  password: string,
  invitationToken: string | undefined = undefined,
  ambassadorToken: string | undefined = undefined
): unknown => {
  return gql.mutation({
    operation: 'createUser',
    variables: {
      userInput: {
        value: { email, password, invitationToken, ambassadorToken },
        type: 'RegisterRequest',
        required: true,
      },
    },
    fields: ['id', 'email', 'isActive', 'isEmailValidated'],
  });
};

// validate email
export const validateEmailMutation = (emailCode: string): unknown => {
  return gql.mutation({
    operation: 'validateEmail',
    variables: {
      emailCode: {
        value: emailCode,
        required: true,
      },
    },
    fields: ['status'],
  });
};

// resend code
export const resendCode = (): unknown => {
  return gql.mutation({
    operation: 'sendBackEmail',
    fields: ['status'],
  });
};

// change password request
export const requestForgetPasswordToken = (email: string): unknown => {
  return gql.mutation({
    operation: 'requestForgetPasswordToken',
    variables: {
      email: {
        value: email,
        required: true,
      },
    },
    fields: ['status'],
  });
};

// update forgotten password
export const forgetPassword = (token: string, newPassword: string): unknown => {
  return gql.mutation({
    operation: 'forgetPassword',
    variables: {
      token: {
        value: token,
        required: true,
      },
      newPassword: {
        value: newPassword,
        required: true,
      },
    },
    fields: ['status'],
  });
};
