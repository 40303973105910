import { createAction, props } from '@ngrx/store';
import { HeaderState } from './header.reducer';

export const updateHeader = createAction('[Header] Update Header', props<HeaderState>());

export const cleanHeader = createAction('[Header] Clean Header');

export const catchBackHeader = createAction('[Header] Catch Back Header', props<{ catchBack?: boolean }>());

export const sendCatchEvent = createAction('[Header] Back Header catched');

export const sendInputSubmittedEvent = createAction('[Header] Input submitted');
