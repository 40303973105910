import { createAction, props } from '@ngrx/store';
import { Artist } from 'src/app/shared/models/artist.type';
import { Album } from '../models/album.type';
import { DashboardDirection } from '../models/dashboard-direction.type';
import { Manager } from '../models/manager.type';
import { Playlist } from '../models/playlist.type';
import { Song } from '../../../shared/models/song.type';

export const setDashboardDirection = createAction(
  '[Dashboard] Dashboard internal navigation - set',
  props<{ direction: DashboardDirection }>()
);
export const goDashboardDirection = createAction(
  '[Dashboard] Dashboard internal navigation - go',
  props<{ direction: DashboardDirection; path: string[] }>()
);
export const backDashboardDirection = createAction('[Dashboard] Dashboard internal navigation - back');

// TODO: Weill be load albums by artist id
// ALBUMS
export const loadAlbums = createAction('[Dashboard] Load albums');
export const loadAlbumsSuccess = createAction('[Dashboard] Load albums success', props<{ albums: Album[] }>());

export const addAlbum = createAction('[Dashboard] Add album', props<{ album: Album; coverFile?: any }>());
export const addAlbumSuccess = createAction('[Dashboard] Add album success', props<{ album: Album }>());

export const changeAlbum = createAction('[Dashboard] Change album', props<{ album: Album; coverFile?: any }>());
export const changeAlbumSuccess = createAction('[Dashboard] Change album success', props<{ album: Album }>());

export const deleteAlbum = createAction('[Dashboard] Delete album', props<{ id: string }>());
export const deleteAlbumSuccess = createAction('[Dashboard] Delete album success', props<{ id: string }>());

// SONGS
export const loadSongs = createAction('[Dashboard] Load songs');
export const loadSongsSuccess = createAction('[Dashboard] Load songs success', props<{ songs: Song[] }>());

export const addSong = createAction('[Dashboard] Add song', props<{ song: Song; streamFile: any; lyricFile?: any }>());
export const addSongSuccess = createAction('[Dashboard] Add song success', props<{ song: Song }>());

export const changeSong = createAction(
  '[Dashboard] Change song',
  props<{ song: Song; streamFile?: any; lyricFile?: any }>()
);
export const changeSongSuccess = createAction('[Dashboard] Change song success', props<{ song: Song }>());

export const deleteSong = createAction('[Dashboard] Delete song', props<{ id: string }>());
export const deleteSongSuccess = createAction('[Dashboard] Delete song success', props<{ id: string }>());

// TOP SONGS
export const loadTopSongs = createAction('[Dashboard] Load top songs');
export const loadTopSongsSuccess = createAction(
  '[Music Library] Load top songs success',
  props<{ topSongs: Song[] }>()
);

// MANAGERS
export const loadManagers = createAction('[Dashboard] Load managers');
export const loadManagersSuccess = createAction('[Dashboard] Load managers success', props<{ managers: Manager[] }>());

// ARTISTS
export const loadArtists = createAction('[Dashboard] Load artists');
export const loadArtistsSuccess = createAction('[Dashboard] Load artists success', props<{ artists: Artist[] }>());

// PLAYLISTS
export const loadPlaylists = createAction('[Dashboard] Load playlists');
export const loadPlaylistsSuccess = createAction(
  '[Dashboard] Load playlists success',
  props<{ playlists: Playlist[] }>()
);

export const addPlaylist = createAction('[Dashboard] Add playlist', props<{ playlist: Playlist; coverFile?: any }>());
export const addPlaylistSuccess = createAction('[Dashboard] Add playlist success', props<{ playlist: Playlist }>());

export const changePlaylist = createAction(
  '[Dashboard] Change playlist',
  props<{ playlist: Playlist; coverFile?: any }>()
);
export const changePlaylistSuccess = createAction(
  '[Dashboard] Change playlist success',
  props<{ playlist: Playlist }>()
);

export const deletePlaylist = createAction('[Dashboard] Delete playlist', props<{ id: string }>());
export const deletePlaylistSuccess = createAction('[Dashboard] Delete playlist success', props<{ id: string }>());
